<template>
    <div>
      <c-table-event
        :title="title"
        :items="getItems"
        :columns="columns"
        :totalRows="totalRows"
        :perPage="perPage"
        :currentPage="currentPage"
        :api="api"
        @changeCurrentPage="changeCurrentPage"
        @changePerPage="changePerPage"
      ></c-table-event>
    </div>
  </template>
  
  <script>
  import {
    BCard,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdownItem,
    BDropdown,
  } from "bootstrap-vue";
  import { VueGoodTable } from "vue-good-table";
  import "prismjs";
  import "prismjs/themes/prism-tomorrow.css";
  import table from "@/libs/table";
  import CTableEvent from "@/components/CTableEvent.vue";
  import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
  
  export default {
    components: {
      BCard,
      VueGoodTable,
      BAvatar,
      BBadge,
      BPagination,
      BFormGroup,
      BFormInput,
      BFormSelect,
      BDropdown,
      BDropdownItem,
      CTableEvent,
      ToastificationContent,
    },
    data() {
      return {
        title: "Kalendar Akademik",
        api: "events",
        dir: false,
        items: [],
        totalRows: 0,
        perPage: 10,
        limit: 0,
        orderBy: "createdAt",
        sortBy: "desc",
        currentPage: 1,
        search: "",
        columns: [
          { key: "id", label: "#", sortable: false },
          { key: "no", label: "#", sortable: false },
          { key: "title", label: "judul", sortable: true },
          { key: "place", label: "tempat", sortable: true },
          { key: "startDate", label: "tanggal mulai", sortable: true },
          { key: "endDate", label: "tanggal berakhir", sortable: true },
          {
            key: "action",
            label: "aksi",
            class: "text-center",
            actions: { show: true },
          },
        ],
      };
    },
    mounted() {
      const _ = this;
      if (_.$route.params) {
        if (_.$route.params.variant) {
          _.toastNotification(
            _.$route.params.variant,
            "BellIcon",
            _.$route.params.text
          );
        }
      }
    },
    methods: {
      getItems(ctx, callback) {
        table.getItems(ctx, callback, this);
      },
      changeCurrentPage(page) {
        table.changeCurrentPage(this, page);
      },
      changePerPage(perPage) {
        table.changePerPage(this, perPage);
      },
      toastNotification(variant, icon, text) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Pemberitahuan",
            icon,
            text,
            variant,
          },
        });
      },
    },
  };
  </script>
  